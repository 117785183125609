import React, { useState, useCallback, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker-custom.css";
import styles from "./index.module.scss";
import searchIcon from "../../assets/searchIcon.svg";
import ButtonRade from "../../components/RadeButtons";
import cx from "classnames";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import { radexAuth, payezyFirestore } from "../../firebase";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import USDFlagIcon from "../../assets/USFlag.svg";
import IndiaFlagIcon from "../../assets/indiaFlagIcon.svg";
import { doc, getDoc } from "firebase/firestore";
import DatePicker from "react-datepicker";
import TickIcon from "../../assets/TickIcon";
import CopyIcon from "../../assets/CopyIcon";
import { Link } from "react-router-dom";
import ConfirmRefundModal from "../ConfirmRefundModal/ConfirmRefundModal"; // Import the modal component
import Modal from "react-modal";

const limit = 50; // Items per page
const MAX_DECIMAL_PLACE = 2;
const PZTransfers = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [txCurrentPage, setTxCurrentPage] = useState(1);
  const [cache, setCache] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [openTransactionDetails, setOpenTransactionDetails] = useState(null);
  const [senderNames, setSenderNames] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null); // Track the index of the currently copied text
  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
  const [refundData, setRefundData] = useState(null); // State to store refund data
  const [selectedUserCustomerGuid, setSelectedUserCustomerGuid] =
    useState(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState(null); // State to store selected user email

  const handleCopyClick = (value, index) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // Copying to clipboard succeeded
        console.log("Copied to clipboard:", value);
        setCopiedIndex(index); // Set the index of the copied text
        setTimeout(() => {
          setCopiedIndex(false); // Change back to copy icon after a delay
        }, 5000);
      })
      .catch((error) => {
        // Copying to clipboard failed
        console.error("Failed to copy to clipboard:", error);
      });
  };

  // Search filter
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  // Function to handle refund button click
  const handleClickRefundUsd = (
    tx_id,
    usd_amount,
    customer_guid,
    user_email
  ) => {
    setRefundData({ txId: tx_id, amount: usd_amount });
    setSelectedUserCustomerGuid(customer_guid);
    setSelectedUserEmail(user_email);
    setModalIsOpen(true);
  };
  // Add filtered transactions logic
  const getFilteredTransactions = useCallback(() => {
    const searchValue = searchTerm.toLowerCase().trim();

    return transactions
      .filter((transaction) => {
        // Only filter by transaction ID
        const txIdMatch = transaction.tx_id.toLowerCase().includes(searchValue);

        // Add date filtering
        const dateMatch = selectedDate
          ? new Date(transaction.created_at).toDateString() ===
            selectedDate.toDateString()
          : true;

        return txIdMatch && dateMatch;
      })
      .sort((a, b) => {
        // Sort by created_at in descending order (newest first)
        return new Date(b.created_at) - new Date(a.created_at);
      });
  }, [transactions, searchTerm, selectedDate]);
  const fetchSenderDetails = async (email) => {
    if (senderNames[email]) return senderNames[email];

    try {
      const userDocRef = doc(payezyFirestore, "userData", email);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const { first, middle, last } = userData.user_pii.name;
        const fullName = `${first} ${middle} ${last}`.trim();

        setSenderNames((prev) => ({
          ...prev,
          [email]: fullName,
        }));

        return fullName;
      } else {
        console.log("User data not found");
        return "-";
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      return "-";
    }
  };
  const fetchTransactions = useCallback(
    async (page = 1) => {
      // Check if the page is already in cache
      if (cache[page]) {
        setTransactions(cache[page].transactions);
        setLastEvaluatedKey(cache[page].lastEvaluatedKey);
        return;
      }

      try {
        const key = page === 1 ? null : lastEvaluatedKey;

        // Obtain ID token
        const idToken = await radexAuth.currentUser.getIdToken(true);
        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        };
        // Include lastEvaluatedKey in the URL if it exists
        const url = new URL(
          process.env.REACT_APP_MANGO_SERVER_URI + `/admin-get-transfer-history`
        );
        url.searchParams.append("limit", limit);
        if (key) {
          const keyToUse = typeof key === "string" ? key : JSON.stringify(key);
          url.searchParams.append("lastEvaluatedKey", keyToUse);
        }
        const response = await fetch(url, requestOptions);

        if (response.ok) {
          const responseData = await response.json();
          // Update cache
          setCache((prevCache) => ({
            ...prevCache,
            [page]: {
              transactions: responseData.transactions,
              lastEvaluatedKey: responseData.lastEvaluatedKey,
            },
          }));

          setTransactions(responseData.transactions);
          // store the lastEvaluatedKey from response
          setLastEvaluatedKey(responseData.lastEvaluatedKey);
        } else {
          const errorData = await response.json();
          console.log("errorData, purchase details:", errorData);
        }
      } catch (err) {
        console.error("Error fetching user transactions:", err);
      }
    },
    [lastEvaluatedKey, cache]
  );

  useEffect(() => {
    fetchTransactions(txCurrentPage);
  }, [txCurrentPage, fetchTransactions]);

  useEffect(() => {
    transactions.forEach((transaction) => {
      if (transaction.userEmail) {
        fetchSenderDetails(transaction.userEmail);
      }
    });
  }, [transactions]);

  const handleNextPage = () => {
    if (lastEvaluatedKey) {
      setTxCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (txCurrentPage > 1) {
      setTxCurrentPage((prevPage) => prevPage - 1);
    }
  };
  // Function that format the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);
    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };
    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };
    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);
    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;
    return formattedTime;
  }
  return (
    <>
      <div className={styles.pzTransfersMainContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.searchbarAndFilter}>
            <div className={styles.searchBarContainer}>
              <input
                type="text"
                className={styles.searchBar}
                placeholder="Search by Transaction ID"
                value={searchTerm}
                onChange={handleSearch}
              />
              <img
                src={searchIcon}
                className={styles.searchIcon}
                alt="searchIcon"
              />
            </div>
            <div className={styles.filterButtonContainer}>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                placeholderText="Select date"
                className={styles.datePicker}
                dateFormat="MM/dd/yy"
                isClearable
                wrapperClassName={styles.datePickerWrapper}
              />
            </div>
          </div>
          <div className={styles.transferPaginationContainer}>
            <div className={styles.previousButtonContainer}>
              <ButtonRade
                customStyling={cx(styles.previousButton, {
                  [styles.disabledButton]: txCurrentPage === 1,
                })}
                onClick={handlePreviousPage}
                disabled={txCurrentPage === 1}
              >
                {PZ_USER_ENUM.previousButton}
              </ButtonRade>
            </div>
            <div className={styles.pageNumber}>Page {txCurrentPage}</div>
            <div className={styles.nextButtonContainer}>
              <ButtonRade
                customStyling={cx(styles.nextButton, {
                  [styles.disabledButton]: !lastEvaluatedKey,
                })}
                onClick={handleNextPage}
                disabled={!lastEvaluatedKey}
              >
                {PZ_USER_ENUM.nextButton}
              </ButtonRade>
            </div>
          </div>
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.transfersListMainContainer}>
            <div className={styles.txListHeadings}>
              <div className={styles.txDateKey}>
                {PZ_TRANSFER_ENUM.dateAndTime}
              </div>
              <div className={styles.txIdKey}>{PZ_TRANSFER_ENUM.txId}</div>
              <div className={styles.txAmountKey}>
                {PZ_TRANSFER_ENUM.amount}
              </div>
              <div className={styles.txSenderKey}>
                {PZ_TRANSFER_ENUM.sender}
              </div>
              <div className={styles.txBeneficiaryKey}>
                {PZ_TRANSFER_ENUM.beneficiary}
              </div>
            </div>
            <div className={styles.txListDetailsContainer}>
              {getFilteredTransactions().map((post) => (
                <div
                  key={post.tx_id}
                  className={cx(styles.txListDetails, {
                    [styles.txListDetailsIfClicked]:
                      openTransactionDetails?.tx_id === post.tx_id,
                  })}
                  onClick={() => setOpenTransactionDetails(post)}
                >
                  <div className={styles.txDateValue}>
                    {post?.created_at ? (
                      <>
                        <div className={styles.txFormattedDate}>
                          {new Date(post.created_at).toLocaleDateString(
                            "en-US",
                            {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}
                        </div>
                        <div className={styles.txFormattedTime}>
                          {new Date(post.created_at).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                            }
                          )}
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div
                    className={`${styles.txIdValue} ${styles.tooltip}`}
                    data-tooltip={post.tx_id}
                  >
                    {post.tx_id
                      ? `${post.tx_id.slice(0, 4)}...${post.tx_id.slice(-4)}`
                      : "-"}
                  </div>
                  <div className={styles.txAmountValue}>
                    $
                    {post?.tx_data?.net_usd_amt_in
                      ? Number(post.tx_data.net_usd_amt_in).toFixed(
                          MAX_DECIMAL_PLACE
                        )
                      : "-"}
                  </div>
                  <div className={styles.txSenderValue}>
                    {post?.userEmail ? senderNames[post.userEmail] || "-" : "-"}
                  </div>
                  <div className={styles.txBeneficiaryValue}>
                    {post?.receiver_data?.receiver_name
                      ? post.receiver_data.receiver_name
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.selectedTransferDetails}>
            {openTransactionDetails && (
              <div className={styles.transactionsDetails}>
                <div className={styles.flexContainer}>
                  <div className={styles.inrAmount}>
                    <img
                      src={IndiaFlagIcon}
                      alt="IndiaFlagIcon"
                      className={styles.flagIcon}
                    />{" "}
                    {openTransactionDetails?.tx_data?.inr_amt_out
                      ? Number(
                          openTransactionDetails?.tx_data.inr_amt_out
                        ).toFixed(MAX_DECIMAL_PLACE)
                      : "-"}{" "}
                    INR
                  </div>
                  <div className={styles.usdAmount}>
                    <img
                      src={USDFlagIcon}
                      alt="USDFlagIcon"
                      className={styles.flagIcon}
                    />{" "}
                    {openTransactionDetails?.tx_data?.net_usd_amt_in
                      ? Number(
                          openTransactionDetails?.tx_data.net_usd_amt_in
                        ).toFixed(MAX_DECIMAL_PLACE)
                      : "-"}{" "}
                    USD
                  </div>
                  <div className={styles.timestamp}>
                    {openTransactionDetails?.created_at
                      ? formatEpochTime(openTransactionDetails?.created_at)
                      : "-"}
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.transactionIdContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.transferId}
                    </div>
                    <div className={styles.valueAndCopyIconContainer}>
                      <div className={styles.txDetailsValue}>
                        {openTransactionDetails?.tx_id
                          ? openTransactionDetails?.tx_id
                          : "-"}
                      </div>
                      {openTransactionDetails?.tx_id && (
                        <div
                          className={styles.copyImageConatiner}
                          onClick={() => {
                            handleCopyClick(openTransactionDetails?.tx_id, 0);
                          }}
                        >
                          {copiedIndex === 0 ? (
                            <TickIcon className={styles.copyIcon} />
                          ) : (
                            <CopyIcon className={styles.copyIcon} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {openTransactionDetails?.tx_status && (
                    <div className={styles.statusButton}>
                      {(openTransactionDetails?.tx_status === "initiated" ||
                        openTransactionDetails?.tx_status ===
                          "payment_init") && (
                        <ButtonRade
                          customStyling={styles.statusButtonProcessing}
                        >
                          {PZ_TRANSFER_ENUM.processingButton}
                        </ButtonRade>
                      )}
                      {(openTransactionDetails?.tx_status ===
                        "payment_received" ||
                        openTransactionDetails?.tx_status ===
                          "usd_exchange_complete") && (
                        <ButtonRade
                          customStyling={styles.statusButtonProcessing}
                        >
                          {PZ_TRANSFER_ENUM.paymentRecievedButton}
                        </ButtonRade>
                      )}
                      {openTransactionDetails?.tx_status ===
                        "delivery_complete" && (
                        <ButtonRade
                          customStyling={styles.statusButtonCompleted}
                        >
                          {PZ_TRANSFER_ENUM.completedButton}
                        </ButtonRade>
                      )}
                      {openTransactionDetails?.tx_status ===
                        "payment_rejected" && (
                        <ButtonRade
                          customStyling={styles.statusButtonCancelled}
                        >
                          {PZ_TRANSFER_ENUM.cancelledButton}
                        </ButtonRade>
                      )}
                      {openTransactionDetails?.tx_status ===
                        "payment_failed" && (
                        <ButtonRade
                          customStyling={styles.statusButtonCancelled}
                        >
                          {PZ_TRANSFER_ENUM.failedButton}
                        </ButtonRade>
                      )}
                      {(openTransactionDetails?.tx_status ===
                        "refund_processed" ||
                        openTransactionDetails?.tx_status ===
                          "refund_initiated") && (
                        <ButtonRade
                          customStyling={styles.statusButtonProcessing}
                        >
                          {PZ_TRANSFER_ENUM.processingRefundButton}
                        </ButtonRade>
                      )}
                      {openTransactionDetails?.tx_status ===
                        "refund_complete" && (
                        <ButtonRade
                          customStyling={styles.statusButtonCompleted}
                        >
                          {PZ_TRANSFER_ENUM.refundCompleteButton}
                        </ButtonRade>
                      )}
                      {openTransactionDetails?.tx_status ===
                        "delivery_rejected" && (
                        <ButtonRade
                          customStyling={styles.statusButtonCancelled}
                        >
                          {PZ_TRANSFER_ENUM.settlementFailedButton}
                        </ButtonRade>
                      )}
                      {openTransactionDetails?.tx_status ===
                        "retry_delivery" && (
                        <ButtonRade
                          customStyling={styles.statusButtonProcessing}
                        >
                          {PZ_TRANSFER_ENUM.paymentRecievedButton}
                        </ButtonRade>
                      )}
                      {(openTransactionDetails?.tx_status === "created" ||
                        ![
                          "initiated",
                          "payment_init",
                          "payment_received",
                          "usd_exchange_complete",
                          "delivery_complete",
                          "payment_rejected",
                          "delivery_rejected",
                          "refund_processed",
                          "refund_initiated",
                          "refund_complete",
                          "payment_failed",
                          "retry_delivery",
                        ].includes(openTransactionDetails?.tx_status)) && (
                        <ButtonRade
                          customStyling={styles.statusButtonProcessing}
                        >
                          {PZ_TRANSFER_ENUM.processingButton}
                        </ButtonRade>
                      )}
                    </div>
                  )}
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.nickNameContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.nickName}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.receiver_data?.receiver_nickname
                        ? openTransactionDetails?.receiver_data
                            ?.receiver_nickname
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.fullNameContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.fullName}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.receiver_data?.receiver_name
                        ? openTransactionDetails?.receiver_data?.receiver_name
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.phoneContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.phone}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.receiver_data?.receiver_phone
                        ? openTransactionDetails?.receiver_data?.receiver_phone
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.accountNoContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.accountNo}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.receiver_data
                        ?.receiver_bank_number
                        ? openTransactionDetails?.receiver_data
                            ?.receiver_bank_number
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.fullNameContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.transactionFee}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.tx_data?.tx_cost
                        ? Number(
                            openTransactionDetails?.tx_data?.tx_cost
                          ).toFixed(MAX_DECIMAL_PLACE)
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.phoneContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.ifscCode}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.receiver_data?.receiver_bank_ifsc
                        ? openTransactionDetails?.receiver_data
                            ?.receiver_bank_ifsc
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.paymentRefContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.paymentRef}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.payment_reference
                        ? openTransactionDetails?.payment_reference
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.fullNameContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.totalCost}
                    </div>
                    <div className={styles.txDetailsValue}>
                      $
                      {openTransactionDetails?.tx_data?.net_usd_amt_in
                        ? Number(
                            openTransactionDetails?.tx_data?.net_usd_amt_in
                          ).toFixed(MAX_DECIMAL_PLACE)
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.phoneContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.lastUpdated}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.tx_data?.last_modified_at
                        ? formatEpochTime(
                            openTransactionDetails?.tx_data?.last_modified_at
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.fullNameContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.exchangeRate}
                    </div>
                    <div className={styles.txDetailsValue}>
                      {openTransactionDetails?.tx_data?.base_exchange_rate
                        ? openTransactionDetails?.tx_data?.base_exchange_rate
                        : "-"}
                    </div>
                  </div>
                  <div className={styles.phoneContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.promoRate}
                    </div>
                    {openTransactionDetails?.tx_data?.final_exchange_rate ? (
                      <div className={styles.txDetailsValue}>
                        {openTransactionDetails?.tx_data
                          ?.final_exchange_rate ===
                        openTransactionDetails?.tx_data.base_exchange_rate
                          ? "-"
                          : openTransactionDetails?.tx_data
                              ?.final_exchange_rate}
                      </div>
                    ) : (
                      <div className={styles.txDetailsValue}>{"-"}</div>
                    )}
                  </div>
                </div>
                <div className={styles.flexContainer}>
                  <div className={styles.fullNameContainer}>
                    <div className={styles.txDetailsKey}>
                      {PZ_TRANSFER_ENUM.senderEmail}
                    </div>
                    <div className={styles.valueAndCopyIconContainer}>
                      <div className={styles.txDetailsValue}>
                        {openTransactionDetails?.userEmail ? (
                          <Link
                            to={`/pzUsers?email=${openTransactionDetails.userEmail}`}
                            className={styles.link}
                          >
                            {openTransactionDetails.userEmail}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </div>
                      {openTransactionDetails?.userEmail && (
                        <div
                          className={styles.copyImageConatiner}
                          onClick={() => {
                            handleCopyClick(
                              openTransactionDetails.userEmail,
                              1
                            );
                          }}
                        >
                          {copiedIndex === 1 ? (
                            <TickIcon className={styles.copyIcon} />
                          ) : (
                            <CopyIcon className={styles.copyIcon} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {openTransactionDetails?.tx_status !==
                    "delivery_complete" && (
                    <div className={styles.refundButtonContainer}>
                      <ButtonRade
                        customStyling={styles.refundButton}
                        onClick={() =>
                          handleClickRefundUsd(
                            openTransactionDetails?.tx_id,
                            openTransactionDetails?.tx_data.net_usd_amt_in,
                            openTransactionDetails?.cybrid_customer_guid,
                            openTransactionDetails?.userEmail
                          )
                        }
                      >
                        {PZ_TRANSFER_ENUM.refund}
                      </ButtonRade>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmRefundModal
          setModalIsOpen={setModalIsOpen}
          refundData={refundData}
          selectedUserCustomerGuid={selectedUserCustomerGuid}
          selectedUserEmail={selectedUserEmail}
        />
      </Modal>
    </>
  );
};

export default PZTransfers;
