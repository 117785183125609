import React from "react";

import styles from "./index.module.scss";
const SendReplyPopUp = ({
  setModalIsOpen,
  selectedTicketID,
  handleReplyChange,
  handleSaveReply,
  replyDrafts,
  errorMessage,
  setErrorMessage,
}) => {
  return (
    <div className={styles.sendReplyPopUpContainer}>
      <div className={styles.textAreaContainer}>
        <textarea
          className={styles.replyMessage}
          type="text"
          placeholder="Type your reply here"
          value={replyDrafts[selectedTicketID] || ""}
          onChange={(event) => handleReplyChange(selectedTicketID, event)}
        />
      </div>

      <div className={styles.buttonContainer}>
        <button
          className={styles.sendButton}
          onClick={() => {
            setModalIsOpen(false);
            setErrorMessage(""); // Close the modal
          }}
        >
          Cancel
        </button>
        <button
          className={styles.sendButton}
          onClick={() => {
            handleSaveReply(); // Call the handleSaveReply function
          }}
        >
          Reply
        </button>
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default SendReplyPopUp;
