import React, { useEffect, useState } from "react";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { payezyFirestore } from "../../firebase";
import { radexAuth } from "../../firebase";
import USDFlagIcon from "../../assets/USFlag.svg";
import IndiaFlagIcon from "../../assets/indiaFlagIcon.svg";
import SelectBox from "../../components/SelectBox/SelectBox";
import CopyIcon from "../../assets/CopyIcon";
import TickIcon from "../../assets/TickIcon";
import ConfirmRefundModal from "../ConfirmRefundModal/ConfirmRefundModal";
import Modal from "react-modal";

const MAX_DECIMAL_PLACE = 2; //Variable that defines maximum decimal place
const PzSearchUserTransfers = () => {
  const [pzUserData, setPzUserData] = useState([]); //state to store the user data
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [selectedUserCustomerGuid, setSelectedUserCustomerGuid] =
    useState(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState(null); // State to store selected user email
  const [transactionOptions, setTransactionOptions] = useState([]); // State to store filtered transactions
  const [error, setError] = useState("");
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [refundData, setRefundData] = useState(null);
  // State to open and close the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // handle on click copy button
  const handleCopyClick = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // Copying to clipboard succeeded
        console.log("Copied to clipboard:", value);
        setCopiedIndex(value);
        setTimeout(() => {
          setCopiedIndex(false); // Change back to copy icon after a delay
        }, 5000);
      })
      .catch((error) => {
        // Copying to clipboard failed
        console.error("Failed to copy to clipboard:", error);
      });
  };
  //Fetch the userData collection from the firebase to fetch the user details
  const getPzUsers = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({
          id: doc.id, // Document ID
          ...doc.data(), // Spread the document data
        });
      });
      setPzUserData(userData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getPzUsers();
  }, []);
  // Map user data to options for user selection
  const userOptions = pzUserData.map((user) => ({
    label: user.id, // Use email as label
    value: user.id,
    data: user, // Include entire user data
  }));

  // Fetch and filter transactions with status "payment_init" for the selected user
  const getFilteredTransactions = async (userEmail) => {
    try {
      const transactionRef = collection(
        payezyFirestore,
        "transactions",
        userEmail,
        "userTransactions"
      );

      // Fetch all transactions for the user in a single call
      const querySnapshot = await getDocs(transactionRef);
      const filteredTransactions = [];

      // Filter transactions with status "payment_init"
      querySnapshot.forEach((doc) => {
        const transactionData = doc.data();
        if (transactionData.transaction_details?.status === "payment_init") {
          filteredTransactions.push({
            label: doc.id, // Use transaction ID as label
            value: doc.id, // Transaction ID as value
          });
        }
      });

      // Update transaction options with only transactions having "payment_init" status
      setTransactionOptions(filteredTransactions);
    } catch (error) {
      console.error("Error fetching filtered transactions:", error);
    }
  };

  const handleClickRefundUsd = (tx_id, usd_amount) => {
    setRefundData({ txId: tx_id, amount: usd_amount });
    setModalIsOpen(true);
  };

  // Call this function when the selected user changes to get filtered transactions
  const handleUserChange = (newValue) => {
    setError("");
    setTransactionOptions([]); // Clear transaction options dropdown
    setSelectedTransaction(null);
    setTransactionDetails(null);
    setSelectedUser(newValue); // set selected user
    setSelectedUserCustomerGuid(
      newValue?.data?.cybrid_customer_details?.customer_guid
    ); // Set customer guid
    setSelectedUserEmail(newValue?.data?.id); // Set email

    getFilteredTransactions(newValue?.data?.id); // Pass userEmail and transaction IDs
  };

  const handleTransactionChange = (newValue) => {
    setError("");
    setTransactionDetails(null);
    setSelectedTransaction(newValue); // Set selected transaction
  };

  const handleSearch = async () => {
    if (!selectedUser || !selectedTransaction) {
      console.error("Please select both user and transaction.");
      return;
    }

    try {
      const idToken = await radexAuth.currentUser.getIdToken(true);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_MANGO_SERVER_URI +
          `/admin-get-user-transfer-details/${selectedUser.value}/${selectedTransaction.value}`,
        requestOptions
      );
      console.log("response", response);
      if (response.ok) {
        const result = await response.json();
        setTransactionDetails(result.transaction);
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    }
  };

  // Function that format the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }

  return (
    <>
      <div>
        <div className={styles.dropDownMainContainer}>
          <SelectBox
            placeHolder="Select User Email"
            options={userOptions}
            onChange={handleUserChange}
            setSelectedValue={setSelectedUser}
            selectedValue={selectedUser?.label || ""}
            errorDismissOnclick={() => {}}
            allowCustomInput={true}
          />
          {selectedUser && transactionOptions.length > 0 && (
            <SelectBox
              placeHolder="Select Transaction ID"
              options={transactionOptions}
              onChange={handleTransactionChange}
              setSelectedValue={setSelectedTransaction}
              selectedValue={selectedTransaction?.label || ""}
              errorDismissOnclick={() => {}}
              allowCustomInput={true}
            />
          )}
          {selectedTransaction && (
            <ButtonRade
              customStyling={styles.searchButton}
              onClick={handleSearch}
            >
              Search
            </ButtonRade>
          )}
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
        {transactionDetails !== null && (
          <div className={styles.pzTransfersMainContainer}>
            <div className={styles.flexContainer}>
              <div className={styles.inrAmount}>
                <img
                  src={IndiaFlagIcon}
                  alt="IndiaFlagIcon"
                  className={styles.flagIcon}
                />{" "}
                {transactionDetails.tx_data.inr_amount
                  ? Number(transactionDetails.tx_data.inr_amount).toFixed(
                      MAX_DECIMAL_PLACE
                    )
                  : "-"}{" "}
                INR
              </div>
              <div className={styles.usdAmount}>
                <img
                  src={USDFlagIcon}
                  alt="USDFlagIcon"
                  className={styles.flagIcon}
                />{" "}
                {transactionDetails.tx_data.usd_amount
                  ? Number(transactionDetails.tx_data.usd_amount).toFixed(
                      MAX_DECIMAL_PLACE
                    )
                  : "-"}{" "}
                USD
              </div>
              <div className={styles.timestamp}>
                {transactionDetails.tx_data.created_at
                  ? formatEpochTime(transactionDetails.tx_data.created_at)
                  : "-"}
              </div>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.transactionIdContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.transferId}
                </div>
                <div className={styles.txIdValueContainer}>
                  <div className={styles.txIdValue}>
                    {transactionDetails.tx_id ? transactionDetails.tx_id : "-"}
                  </div>
                  {transactionDetails.tx_id && (
                    <div
                      className={styles.copyIconContainer}
                      onClick={() => handleCopyClick(transactionDetails.tx_id)}
                    >
                      {copiedIndex ? (
                        <TickIcon className={styles.copyIcon} />
                      ) : (
                        <CopyIcon className={styles.copyIcon} />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {transactionDetails.tx_status && (
                <div className={styles.statusButton}>
                  {(transactionDetails.tx_status === "initiated" ||
                    transactionDetails.tx_status === "payment_init") && (
                    <ButtonRade customStyling={styles.statusButtonProcessing}>
                      {PZ_TRANSFER_ENUM.processingButton}
                    </ButtonRade>
                  )}
                  {(transactionDetails.tx_status === "payment_received" ||
                    transactionDetails.tx_status ===
                      "usd_exchange_complete") && (
                    <ButtonRade customStyling={styles.statusButtonProcessing}>
                      {PZ_TRANSFER_ENUM.paymentRecievedButton}
                    </ButtonRade>
                  )}
                  {transactionDetails.tx_status === "delivery_complete" && (
                    <ButtonRade customStyling={styles.statusButtonCompleted}>
                      {PZ_TRANSFER_ENUM.completedButton}
                    </ButtonRade>
                  )}
                  {transactionDetails.tx_status === "payment_rejected" && (
                    <ButtonRade customStyling={styles.statusButtonCancelled}>
                      {PZ_TRANSFER_ENUM.cancelledButton}
                    </ButtonRade>
                  )}
                  {transactionDetails.tx_status === "payment_failed" && (
                    <ButtonRade customStyling={styles.statusButtonCancelled}>
                      {PZ_TRANSFER_ENUM.failedButton}
                    </ButtonRade>
                  )}
                  {(transactionDetails.tx_status === "refund_processed" ||
                    transactionDetails.tx_status === "refund_initiated") && (
                    <ButtonRade customStyling={styles.statusButtonProcessing}>
                      {PZ_TRANSFER_ENUM.processingRefundButton}
                    </ButtonRade>
                  )}
                  {transactionDetails.tx_status === "refund_complete" && (
                    <ButtonRade customStyling={styles.statusButtonCompleted}>
                      {PZ_TRANSFER_ENUM.refundCompleteButton}
                    </ButtonRade>
                  )}
                  {transactionDetails.tx_status === "delivery_rejected" && (
                    <ButtonRade customStyling={styles.statusButtonCancelled}>
                      {PZ_TRANSFER_ENUM.settlementFailedButton}
                    </ButtonRade>
                  )}
                  {transactionDetails.tx_status === "retry_delivery" && (
                    <ButtonRade customStyling={styles.statusButtonProcessing}>
                      {PZ_TRANSFER_ENUM.paymentRecievedButton}
                    </ButtonRade>
                  )}
                  {(transactionDetails.tx_status === "created" ||
                    ![
                      "initiated",
                      "payment_init",
                      "payment_received",
                      "usd_exchange_complete",
                      "delivery_complete",
                      "payment_rejected",
                      "delivery_rejected",
                      "refund_processed",
                      "refund_initiated",
                      "refund_complete",
                      "payment_failed",
                      "retry_delivery",
                    ].includes(transactionDetails.tx_status)) && (
                    <ButtonRade customStyling={styles.statusButtonProcessing}>
                      {PZ_TRANSFER_ENUM.processingButton}
                    </ButtonRade>
                  )}
                </div>
              )}
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.nickNameContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.nickName}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.receiver_data.nick_name
                    ? transactionDetails.receiver_data.nick_name
                    : "-"}
                </div>
              </div>
              <div className={styles.fullNameContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.fullName}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.receiver_data.full_name
                    ? transactionDetails.receiver_data.full_name
                    : "-"}
                </div>
              </div>
              <div className={styles.phoneContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.phone}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.receiver_data.phone_number
                    ? transactionDetails.receiver_data.phone_number
                    : "-"}
                </div>
              </div>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.accountNoContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.accountNo}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.receiver_data.account_number
                    ? transactionDetails.receiver_data.account_number
                    : "-"}
                </div>
              </div>
              <div className={styles.fullNameContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.transactionFee}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.tx_data.tx_cost
                    ? Number(transactionDetails.tx_data.tx_cost).toFixed(
                        MAX_DECIMAL_PLACE
                      )
                    : "-"}
                </div>
              </div>
              <div className={styles.phoneContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.ifscCode}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.receiver_data.ifsc_code
                    ? transactionDetails.receiver_data.ifsc_code
                    : "-"}
                </div>
              </div>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.paymentRefContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.paymentRef}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.payment_reference
                    ? transactionDetails.payment_reference
                    : "-"}
                </div>
              </div>
              <div className={styles.fullNameContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.totalCost}
                </div>
                <div className={styles.txDetailsValue}>
                  $
                  {transactionDetails.tx_data.usd_amount
                    ? Number(transactionDetails.tx_data.usd_amount).toFixed(
                        MAX_DECIMAL_PLACE
                      )
                    : "-"}
                </div>
              </div>
              <div className={styles.phoneContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.lastUpdated}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.tx_data.last_modified_at
                    ? formatEpochTime(
                        transactionDetails.tx_data.last_modified_at
                      )
                    : "-"}
                </div>
              </div>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.exchangeRateContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.exchangeRate}
                </div>
                <div className={styles.txDetailsValue}>
                  {transactionDetails.tx_data.exchange_rate
                    ? transactionDetails.tx_data.exchange_rate
                    : "-"}
                </div>
              </div>
              <div className={styles.paymentRefContainer}>
                <div className={styles.txDetailsKey}>
                  {PZ_TRANSFER_ENUM.promoRate}
                </div>
                {transactionDetails.tx_data.promo_rate ? (
                  <div className={styles.txDetailsValue}>
                    {transactionDetails.tx_data.promo_rate ===
                    transactionDetails.tx_data.exchange_rate
                      ? "-"
                      : transactionDetails.tx_data.promo_rate}
                  </div>
                ) : (
                  <div className={styles.txDetailsValue}>{"-"}</div>
                )}
              </div>

              {transactionDetails.tx_status !== "delivery_complete" && (
                <div className={styles.refundButtonContainer}>
                  <ButtonRade
                    customStyling={styles.refundButton}
                    onClick={() =>
                      handleClickRefundUsd(
                        transactionDetails.tx_id,
                        transactionDetails.tx_data.usd_amount
                      )
                    }
                  >
                    {PZ_TRANSFER_ENUM.refund}
                  </ButtonRade>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <ConfirmRefundModal
          setModalIsOpen={setModalIsOpen}
          refundData={refundData}
          selectedUserCustomerGuid={selectedUserCustomerGuid}
          selectedUserEmail={selectedUserEmail}
        />
      </Modal>
    </>
  );
};

export default PzSearchUserTransfers;
