import React, { useState } from "react";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";
import cx from "classnames";
import { radexAuth } from "../../firebase";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";

const ConfirmRefundModal = ({
  setModalIsOpen,
  refundData,
  selectedUserCustomerGuid,
  selectedUserEmail,
}) => {
  // State to set error message
  const [isLoading, setIsLoading] = useState(false);
  // Handle cancel btton on click
  const handleOnClickCancel = () => {
    setModalIsOpen(false);
  };
  // Handle update btton on click
  const handleClickConfirm = async () => {
    setIsLoading(true);
    try {
      const idToken = await radexAuth.currentUser.getIdToken(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          userEmail: selectedUserEmail,
          amount: refundData?.amount,
          txId: refundData.txId,
        }),
      };
      const response = await fetch(
        process.env.REACT_APP_MANGO_SERVER_URI +
          `/admin-customer-refund/${selectedUserCustomerGuid}`,
        requestOptions
      );
      if (response.ok) {
        const result = await response.json();
        setIsLoading(false);
        setModalIsOpen(false);
        console.log("result", result);
      }
    } catch (error) {
      console.error("Error refunding the amount", error);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className={styles.popUpContent}>
        {PZ_TRANSFER_ENUM.refundConfirmContent}
      </div>
      <div className={styles.popUpButtonDiv}>
        <ButtonRade
          outline
          customStyling={styles.cancelButton}
          onClick={handleOnClickCancel}
        >
          {PZ_TRANSFER_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.updateButton, {
            [styles.disabledButton]: isLoading,
          })}
          onClick={handleClickConfirm}
          disabled={isLoading}
        >
          {PZ_TRANSFER_ENUM.confirmButton}
          {isLoading && (
            <div className={styles.spinnerOverlay}>
              <div className={styles.spinnerContainer} />
            </div>
          )}
        </ButtonRade>
      </div>
    </div>
  );
};

export default ConfirmRefundModal;
