import React, { useState } from "react";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import Radio from "../../components/RadioButtons/RadioButton";
import styles from "./index.module.scss";
import { doc, updateDoc } from "firebase/firestore";
import { payezyFirestore } from "../../firebase";
import ButtonRade from "../../components/RadeButtons";

const TicketStatusSwitchPopUp = ({
  setModalIsOpen,
  selectedTickets, // Now receiving the array of selected tickets
  fetchAllTickets,
  setSelectedTickets,
  setErrorMessage,
  errorMessage,
}) => {
  const [statusSwitchInput, setStatusSwitchInput] = useState(""); // State for storing the status input

  // Function to handle the status switching of the transfer
  const handleClickStatusSwitch = (e) => {
    setErrorMessage("");
    setStatusSwitchInput(e.target.value);
  };

  // Function to handle cancel button
  const handleClickCancelButton = () => {
    setErrorMessage("");
    setModalIsOpen(false);
  };

  const handleClickUpdateButton = async () => {
    setErrorMessage("");
    if (!selectedTickets.length) {
      setErrorMessage("Please select a ticket");
      return;
    }
    if (!statusSwitchInput) {
      setErrorMessage("Please select a status");
      return;
    }
    try {
      // Loop over each selected ticket and update its status in Firestore
      const updatePromises = selectedTickets.map(({ ticketID, email }) => {
        const ticketRef = doc(
          payezyFirestore,
          "ticketCollection",
          email, // Use email to locate the user's document
          "ticketList",
          ticketID // Use ticketID to locate the specific ticket
        );
        return updateDoc(ticketRef, { ticketStatus: statusSwitchInput });
      });

      // Await all update operations
      await Promise.all(updatePromises);
      console.log("Ticket statuses updated successfully!");

      setModalIsOpen(false);
      fetchAllTickets(); // Refresh ticket data
      setSelectedTickets([]); // Rseet the selected tickets
    } catch (error) {
      console.error("Error updating ticket statuses: ", error);
    }
  };

  // Radio button options
  const statusSwitchOptions = [
    { value: "Resolved", label: "Resolved" },
    { value: "Open", label: "Open" },
  ];

  return (
    <div>
      <div className={styles.changeStatus}>{PZ_TRANSFER_ENUM.changeStatus}</div>
      <div className={styles.radioButtonContainer}>
        <Radio
          name="statusSwitchOptions"
          value={statusSwitchInput}
          options={statusSwitchOptions}
          onChangeFunc={handleClickStatusSwitch}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonRade
          customStyling={styles.cancelButton}
          onClick={handleClickCancelButton}
        >
          {PZ_TRANSFER_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          customStyling={styles.updateButton}
          onClick={handleClickUpdateButton}
        >
          {PZ_TRANSFER_ENUM.updateButton}
        </ButtonRade>
      </div>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default TicketStatusSwitchPopUp;
