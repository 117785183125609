export const PZ_USER_ENUM = {
  kyc: "KYC",
  userSince: "User Since",
  fullName: "Full Name",
  address: "Address",
  email: "Email",
  phone: "Phone",
  show: "Show",
  street: "Street",
  street2: "Street2",
  subdivision: "Subdivision",
  countryCode: "Country Code",
  postalCode: "Postal Code",
  city: "City",
  totalTransfersCount: "Total # Transfers",
  totalTransferAmount: "Total Transfers($)",
  customerGuid: "Customer Guid",
  dateOfBirth: "DOB",
  emailAddress: "Email Address",
  documentSubmitted: "Document Submitted",
  documentId: "Document ID",
  transferHistory: "Transfer History",
  transferId: "Transfer ID",
  previousButton: "Previous",
  nextButton: "Next",
  exportButton: "Export",
};
