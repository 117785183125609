export const PZ_TRANSFER_ENUM = {
  dateAndTime: "D/T",
  txId: "TID",
  amount: "Amount",
  sender: "Sender",
  beneficiary: "Beneficiary",
  previousButton: "Previous",
  nextButton: "Next",
  transferId: "Transfer ID",
  accountNo: "Account No.",
  ifscCode: "IFSC Code",
  fullName: "Full Name",
  nickName: "Nick Name",
  phone: "Phone",
  transactionFee: "Transaction Fee",
  totalCost: "Total Cost",
  exchangeRate: "Exchange Rate",
  createdButton: "Created",
  cancelledButton: "Cancelled",
  completedButton: "Settled",
  paymentRecievedButton: "Awaiting settlement",
  processingButton: "Processing",
  paymentRef: "Payment Ref",
  lastUpdated: "Last Updated",
  promoRate: "Promo Rate",
  refund: "Refund",
  failedButton: "Failed",
  processingRefundButton: "Processing Refund",
  refundCompleteButton: "Refund Complete",
  settlementFailedButton: "Settlement Failed",
  settlementErrorButton: "Settlement Error",
  updateButton: "Update",
  cancelButton: "Cancel",
  confirmButton: "Confirm",
  refundConfirmContent: "Are you sure that you want to refund?",
  senderEmail: "Sender Email",
};
