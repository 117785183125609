import React, { useState, useMemo, useEffect } from "react";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import searchIcon from "../../assets/searchIcon.svg";
import filterIcon from "../../assets/filterIcon.svg";
import ButtonRade from "../../components/RadeButtons";
import { payezyFirestore } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import kycVerifiedIcon from "../../assets/kycVerifiedIcon.svg";
import kycReviewIcon from "../../assets/kycReviewIcon.svg";
import kycRejectedIcon from "../../assets/kycRejectedIcon.svg";
import kycUnverifiedIcon from "../../assets/kycUnverified.svg";
import kycFrozenIcon from "../../assets/kycFrozen.svg";
import kycNotInitializedIcon from "../../assets/kycNotInitializedicon.svg";
import Pagination from "../../components/Pagination/Pagination";
import cx from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/datepicker-custom.css";
import styles from "./index.module.scss";
import { saveAs } from "file-saver";
import SelectBox from "../../components/SelectBox/SelectBox";
import DatePicker from "react-datepicker";
import calendarIcon from "../../assets/calendarIcon.svg";

const PzEmailCampaign = () => {
  const [searchTerm, setSearchTerm] = useState(""); //state to store the serach term
  const [pzUsers, setPzUsers] = useState([]); //state to store the user data
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [pageSize, setPageSize] = useState(50); //state to store the page size for pagination
  const [inputValue, setInputValue] = useState(pageSize); // Temporary input value
  const [selectedUsers, setSelectedUsers] = useState([]); // State for selected users
  const [kycFilter, setKycFilter] = useState(""); // State for KYC status filter
  const [filterDate, setFilterDate] = useState(""); // State for the date filter

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow empty value for temporary clearing of the input
    if (value === "") {
      setInputValue("");
      return;
    }

    const numericValue = parseInt(value, 10);

    // Only update if the input is within the allowed range (1-10)
    if (numericValue > 0 && numericValue <= 50) {
      setInputValue(numericValue);
      setPageSize(numericValue); // Update page size immediately
    }
  };
  // Function to handle the filtering of data using the email
  const filteredData = useMemo(() => {
    return pzUsers.filter((post) => {
      return post.id.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [pzUsers, searchTerm]);

  //Function to handle the page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //Function for pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);

  //Fetch he userData collection from the firebase to fetch the user details
  const getPzUsers = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({
          id: doc.id, // Document ID
          ...doc.data(), // Spread the document data
        });
      });

      setPzUsers(userData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPzUsers();
  }, []);

  // Function to handle checkbox change
  const handleCheckboxChange = (userId, userEmail) => {
    setSelectedUsers((prevSelected) => {
      const alreadySelected = prevSelected.find(
        (user) => user.userId === userId
      );
      if (alreadySelected) {
        return prevSelected.filter((user) => user.userId !== userId);
      } else {
        return [...prevSelected, { userId, userEmail }];
      }
    });
  };

  // Function to handle select all users on current page
  const handleSelectAll = () => {
    if (paginatedData.length === 0) return; // No users to select

    const allUserIds = paginatedData.map((user) => user.id);
    const allSelected = allUserIds.every((id) =>
      selectedUsers.some((selected) => selected.userId === id)
    );

    if (allSelected) {
      // Deselect all if all are currently selected
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((selected) => !allUserIds.includes(selected.userId))
      );
    } else {
      // Select all if not all are currently selected
      const newSelectedUsers = paginatedData.map((user) => ({
        userId: user.id,
        userEmail: user.id,
      }));
      setSelectedUsers((prevSelected) => [
        ...new Set([...prevSelected, ...newSelectedUsers]),
      ]);
    }
  };

  // Function to handle KYC status filter change
  const handleKycFilterChange = (option) => {
    setKycFilter(option.value);
    setCurrentPage(1); // Reset to the first page when changing the filter
  };

  // Function to export user data as CSV with filtering
  const exportUsersByKyc = () => {
    const csvRows = [];
    // Header row
    csvRows.push([
      "Email",
      "First Name",
      "Middle Name",
      "Last Name",
      "KYC Status",
    ]);

    // Data rows with filtering based on KYC status
    paginatedData.forEach((user) => {
      const firstName = user?.user_pii?.name?.first || "";
      const middleName = user?.user_pii?.name?.middle || "";
      const lastName = user?.user_pii?.name?.last || "";
      const kycStatus = user?.customer_state || "";

      // Check if the user matches the selected KYC status filter
      if (!kycFilter || kycStatus === kycFilter) {
        csvRows.push([user.id, firstName, middleName, lastName, kycStatus]);
      }
    });

    // Create a Blob from the CSV data
    const blob = new Blob([csvRows.map((row) => row.join(",")).join("\n")], {
      type: "text/csv",
    });
    saveAs(blob, "payezyUsers.csv"); // Use file-saver to save the file
  };

  // Function to handle date filter change
  const handleDateFilterChange = (date) => {
    // Convert the date to dd/mm/yyyy format
    const formattedDate = date
      ? `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${date.getFullYear()}`
      : "";
    setFilterDate(formattedDate);
    setCurrentPage(1); // Reset to the first page when changing the filter
  };

  // Function to export users based on the selected date
  const exportUsersByDate = () => {
    const csvRows = [];
    // Header row
    csvRows.push([
      "Email",
      "First Name",
      "Middle Name",
      "Last Name",
      "KYC Status",
      "User Since", // Add User Since to the CSV
    ]);

    // Filter users based on the selected date
    const filteredUsers = paginatedData.filter((user) => {
      return user.user_since === filterDate; // Match user_since with the selected date
    });

    // Add filtered users to CSV rows
    filteredUsers.forEach((user) => {
      const firstName = user?.user_pii?.name?.first || "";
      const middleName = user?.user_pii?.name?.middle || "";
      const lastName = user?.user_pii?.name?.last || "";
      const kycStatus = user?.customer_state || "";
      const userSince = user?.user_since || ""; // Get user_since

      csvRows.push([
        user.id,
        firstName,
        middleName,
        lastName,
        kycStatus,
        userSince,
      ]);
    });

    // Create a Blob from the CSV data
    const blob = new Blob([csvRows.map((row) => row.join(",")).join("\n")], {
      type: "text/csv",
    });
    saveAs(blob, "filteredUsersByDate.csv"); // Use file-saver to save the file
  };

  console.log("paginatedData", paginatedData);
  return (
    <div className={styles.pzUsersMainContainer}>
      <div className={styles.usersList}>
        <div className={styles.headerContainer}>
          <div className={styles.searchBarContainer}>
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search by Email"
              value={searchTerm}
              onChange={handleSearch}
            />
            <img
              src={searchIcon}
              className={styles.searchIcon}
              alt="searchIcon"
            />
          </div>
          <div className={styles.filterButtonContainer}>
            <ButtonRade customStyling={styles.filterButton}>
              <img
                src={filterIcon}
                alt="filterIcon"
                className={styles.filterIcon}
              />
            </ButtonRade>
          </div>
        </div>

        <div className={styles.usersListsCollection}>
          <ButtonRade
            customStyling={styles.selectAllButton}
            onClick={handleSelectAll}
          >
            Select All
          </ButtonRade>
          {paginatedData.map((user) => (
            <div
              key={user.id}
              className={cx(styles.usersBasicDetailsContainer, {})}
            >
              <div className={styles.checkBoxContainer}>
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(user.id, user.id)}
                  checked={selectedUsers.some(
                    (selected) => selected.userId === user.id
                  )}
                  className={cx(styles["checkbox-input"], {
                    [styles.checked]: selectedUsers.some(
                      (selected) => selected.userId === user.id
                    ), // Apply the animation class when the checkbox is checked
                  })}
                />
                <div className={styles.userNameAndEmail}>
                  <div className={styles.userName}>
                    {`${user?.user_pii?.name?.first || ""} ${
                      user?.user_pii?.name?.middle || ""
                    } ${user?.user_pii?.name?.last || ""}`.trim() || "-"}
                  </div>
                  <div className={styles.userEmail}>{user.id}</div>
                </div>
              </div>
              <div className={styles.userKycDetails}>
                <div className={styles.kycStatus}>
                  {PZ_USER_ENUM.kyc}: {user?.customer_state}
                </div>
                <div className={styles.kycStatusIcon}>
                  {user?.customer_state === "Not Initialized" && (
                    <img
                      src={kycNotInitializedIcon}
                      alt="kycNotInitializedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "unverified" && (
                    <img
                      src={kycUnverifiedIcon}
                      alt="kycUnverifiedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "verified" && (
                    <img
                      src={kycVerifiedIcon}
                      alt="kycVerifiedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "reviewing" && (
                    <img
                      src={kycReviewIcon}
                      alt="kycReviewIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "rejected" && (
                    <img
                      src={kycRejectedIcon}
                      alt="kycRejectedIcon"
                      className={styles.kycIcon}
                    />
                  )}
                  {user?.customer_state === "frozen" && (
                    <img
                      src={kycFrozenIcon}
                      alt="kycFrozenIcon"
                      className={styles.kycIcon}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.paginationContainer}>
          <div className={styles.search}>
            {PZ_USER_ENUM.show}
            <input
              type="number"
              className={styles.searchPage}
              min={1}
              max={10} // Max limit set to 10
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredData.length}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <div className={styles.usersDetails}>
        <div className={styles.kycFilterAndExportContainer}>
          <SelectBox
            placeHolder="Select Filter Option"
            options={[
              { value: "", label: "All Users" },
              { value: "Not Initialized", label: "KYC-Not Initialized" },
              { value: "unverified", label: "KYC-Unverified" },
              { value: "verified", label: "KYC-Verified" },
              { value: "reviewing", label: "KYC-Reviewing" },
              { value: "rejected", label: "KYC-Rejected" },
              { value: "frozen", label: "KYC-Frozen" },
            ]}
            onChange={handleKycFilterChange}
            setSelectedValue={(option) => setKycFilter(option.value)}
            selectedValue={kycFilter}
            errorDismissOnclick={() => {}}
            allowCustomInput={false}
          />

          <ButtonRade
            customStyling={styles.exportButton}
            onClick={exportUsersByKyc}
          >
            Export as CSV
          </ButtonRade>
        </div>
        <div className={styles.dateFilterContainer}>
          <div className={styles.dropdownContainer}>
            <DatePicker
              selected={
                filterDate
                  ? new Date(filterDate.split("/").reverse().join("/"))
                  : null
              }
              onChange={handleDateFilterChange}
              placeholderText="Select date"
              className={styles.datePicker}
              dateFormat="MM/dd/yy"
              isClearable
              wrapperClassName={styles.datePickerWrapper}
            />
            <img src={calendarIcon} alt="calendarIcon" />
          </div>

          <ButtonRade
            customStyling={styles.exportButton}
            onClick={exportUsersByDate}
          >
            Export Users by Date
          </ButtonRade>
        </div>
      </div>
    </div>
  );
};

export default PzEmailCampaign;
