import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { payezyFirestore } from "../../firebase";
import Modal from "react-modal";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";
import TicketStatusSwitchPopUp from "../TicketStatusSwitchPopUp/TicketStatusSwitchPopUp";
import editIcon from "../../assets/editIcon.png";
import SendReplyPopUp from "../SendReplyPopUp/SendReplyPopUp";
import { PZ_TICKETS_ENUM } from "../../enums/pzTicketsEnum";
import cx from "classnames";

const PZServiceTicket = () => {
  // State to store the available emails that have tickets
  const [availableProfileEmails, setAvailableProfileEmails] = useState([]);
  // State to store user ticket data information
  const [PZUserTicketData, setPZUserTicketData] = useState([]);
  // State to open and close the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State to store the selected ticket id
  const [selectedTicketID, setSelectedTicketID] = useState(null);
  // State to determine the current modal page
  const [modalPageNumber, setModalPageNumber] = useState(false);
  // state for selected tickets
  const [selectedTickets, setSelectedTickets] = useState([]);
  // Add new state to track copied items
  const [copiedItems, setCopiedItems] = useState({});
  // Add new state to track reply drafts
  const [replyDrafts, setReplyDrafts] = useState({});
  // State to store error message
  const [errorMessage, setErrorMessage] = useState("");
  // Function to handle changing the ticket status
  const handleClickChangeTicketStatus = () => {
    setModalIsOpen(true);
    setModalPageNumber(0);
  };

  const handleCheckboxChange = (ticketID, email) => {
    setSelectedTickets((prevSelected) => {
      const alreadySelected = prevSelected.find(
        (ticket) => ticket.ticketID === ticketID
      );
      if (alreadySelected) {
        return prevSelected.filter((ticket) => ticket.ticketID !== ticketID);
      } else {
        return [...prevSelected, { ticketID, email }];
      }
    });
  };
  // Effect to fetch the available emails that have support tickets
  useEffect(() => {
    const fetchProfileEmails = async () => {
      try {
        const ticketCollectionRef = collection(
          payezyFirestore,
          "ticketCollection"
        );
        const querySnapshot = await getDocs(ticketCollectionRef);
        const emails = querySnapshot.docs.map((doc) => doc.id);
        setAvailableProfileEmails(emails);
      } catch (error) {
        console.error("Error fetching profile emails: ", error);
      }
    };

    fetchProfileEmails();
  }, []);
  const fetchAllTickets = async () => {
    try {
      const allTickets = [];
      for (const email of availableProfileEmails) {
        const userTicketsRef = collection(
          payezyFirestore,
          "ticketCollection",
          email,
          "ticketList"
        );
        const userTicketsSnapshot = await getDocs(userTicketsRef);
        userTicketsSnapshot.forEach((ticketDoc) => {
          const ticketData = ticketDoc.data();
          allTickets.push({
            email: email,
            ticketID: ticketDoc.id,
            orderID: ticketData.orderID,
            ticketType: ticketData.ticketType,
            message: ticketData.message,
            ticketReply: ticketData.ticketReply,
            ticketStatus: ticketData.ticketStatus,
            createdTimestamp: ticketData.createdTimestamp,
          });
        });
      }
      setPZUserTicketData(allTickets);
    } catch (error) {
      console.error("Error fetching tickets: ", error);
    }
  };
  // Effect to fetch the details of active support tickets
  useEffect(() => {
    // Fetch tickets only when there are available profile emails
    if (availableProfileEmails.length > 0) {
      fetchAllTickets();
    }
  }, [availableProfileEmails]);

  const handleCopy = (text, id) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Set this item as copied
        setCopiedItems((prev) => ({ ...prev, [id]: true }));
        // Reset the "Copied" text after 2 seconds
        setTimeout(() => {
          setCopiedItems((prev) => ({ ...prev, [id]: false }));
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  // Function to handle changes in the reply text area
  const handleReplyChange = (ticketID, event) => {
    setErrorMessage("");
    setReplyDrafts((prev) => ({
      ...prev,
      [ticketID]: event.target.value,
    }));
  };
  // Function to handle opening the reply pop-up
  const handleClickReplyButton = (ticketID) => {
    setSelectedTicketID(ticketID);
    setModalIsOpen(true);
    setModalPageNumber(1);
  };
  // Function to save a reply to Firestore
  const saveReplyToFirestore = async (ticketID, reply, email) => {
    const ticketRef = doc(
      payezyFirestore,
      "ticketCollection",
      email,
      "ticketList",
      ticketID
    );
    try {
      const ticketDoc = await getDoc(ticketRef);

      if (ticketDoc.exists()) {
        await updateDoc(ticketRef, { ticketReply: reply });
        console.log("Reply saved to Firestore!");
      } else {
        console.log("Document does not exist in Firestore.");
      }
    } catch (error) {
      console.error("Error saving reply to Firestore: ", error);
      setErrorMessage("Error saving reply to Firestore.");
    }
  };
  // Function to handle saving replies to multiple tickets
  const handleSaveReply = async () => {
    try {
      const ticket = PZUserTicketData.find(
        (t) => t.ticketID === selectedTicketID
      );
      const replyText = replyDrafts[selectedTicketID];

      // Check if reply is empty or only contains whitespace
      if (!replyText?.trim()) {
        setErrorMessage("Please enter a reply message");
        return; // Don't close modal or proceed with save
      }
      await saveReplyToFirestore(
        selectedTicketID,
        replyDrafts[selectedTicketID],
        ticket.email
      );
      // Update local state only after successful Firebase update
      setPZUserTicketData((prev) =>
        prev.map((t) =>
          t.ticketID === selectedTicketID
            ? { ...t, ticketReply: replyDrafts[selectedTicketID] }
            : t
        )
      );
      // Clear the draft
      setReplyDrafts((prev) => {
        const { [selectedTicketID]: _, ...rest } = prev;
        return rest;
      });
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error saving reply:", error);
      setErrorMessage("Error saving reply to Firestore.");
    }
  };
  // Function to render the content of the modal based on the current page
  const renderModalContent = () => {
    switch (modalPageNumber) {
      case 0:
        return (
          <TicketStatusSwitchPopUp
            setModalIsOpen={setModalIsOpen}
            selectedTickets={selectedTickets}
            fetchAllTickets={fetchAllTickets}
            setSelectedTickets={setSelectedTickets}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
          />
        );
      case 1:
        return (
          <SendReplyPopUp
            setModalIsOpen={setModalIsOpen}
            selectedTicketID={selectedTicketID}
            handleReplyChange={handleReplyChange}
            handleSaveReply={handleSaveReply}
            replyDrafts={replyDrafts}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      <div className={styles.pzTicketsMainContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.pzTicketsHeading}>
            {PZ_TICKETS_ENUM.serviceTicket}
          </div>
          <div className={styles.changeStatusButtonContainer}>
            <ButtonRade
              customStyling={styles.changeStatusButton}
              onClick={handleClickChangeTicketStatus}
            >
              {PZ_TICKETS_ENUM.changeStatus}
              <img src={editIcon} alt="editIcon" />
            </ButtonRade>
          </div>
        </div>
        <div className={styles.bodyContainer}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Email</th>
                <th>TID</th>
                <th>Type</th>
                <th>ST ID</th>
                <th>D/T</th>
                <th>Message</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {PZUserTicketData.map((ticket, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() =>
                        handleCheckboxChange(ticket.ticketID, ticket.email)
                      }
                      checked={selectedTickets.some(
                        (selected) => selected.ticketID === ticket.ticketID
                      )}
                      className={cx(styles["checkbox-input"], {
                        [styles.checked]: selectedTickets.some(
                          (selected) => selected.ticketID === ticket.ticketID
                        ), // Apply the animation class when the checkbox is checked
                      })}
                    />
                  </td>
                  <td
                    className={`${styles.tooltip}`}
                    onClick={() =>
                      handleCopy(ticket.email, `email-${ticket.ticketID}`)
                    }
                    data-tooltip={
                      copiedItems[`email-${ticket.ticketID}`]
                        ? "Copied!"
                        : "Click to copy"
                    }
                  >
                    {ticket.email ? ticket.email : "-"}
                  </td>
                  {ticket.orderID ? (
                    <td
                      className={`${styles.tooltip} ${styles.copyable}`}
                      data-tooltip={
                        copiedItems[`order-${ticket.ticketID}`]
                          ? "Copied!"
                          : `${ticket.orderID} (Click to copy)`
                      }
                      onClick={() =>
                        handleCopy(ticket.orderID, `order-${ticket.ticketID}`)
                      }
                    >
                      {ticket.orderID.slice(0, 4) +
                        "..." +
                        ticket.orderID.slice(-2)}
                    </td>
                  ) : (
                    <td>-</td>
                  )}
                  <td>{ticket.ticketType ? ticket.ticketType : "-"}</td>
                  {ticket.ticketID ? (
                    <td
                      className={`${styles.tooltip} ${styles.copyable}`}
                      data-tooltip={
                        copiedItems[`ticket-${ticket.ticketID}`]
                          ? "Copied!"
                          : `${ticket.ticketID} (Click to copy)`
                      }
                      onClick={() =>
                        handleCopy(ticket.ticketID, `ticket-${ticket.ticketID}`)
                      }
                    >
                      {ticket.ticketID.slice(0, 3)}...
                      {ticket.ticketID.slice(-2)}
                    </td>
                  ) : (
                    <td>-</td>
                  )}
                  <td>
                    {ticket.createdTimestamp ? (
                      <>
                        <div className={styles.txFormattedDate}>
                          {new Date(ticket.createdTimestamp).toLocaleDateString(
                            "en-US",
                            {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}
                        </div>
                        <div className={styles.txFormattedTime}>
                          {new Date(ticket.createdTimestamp).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                            }
                          )}
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{ticket.message ? ticket.message : "-"}</td>
                  <td>{ticket.ticketStatus ? ticket.ticketStatus : "-"}</td>
                  <td className={styles.replyTextarea}>
                    <button
                      className={`${styles.sendButton} ${
                        ticket.ticketReply ? styles.repliedMessagetooltip : ""
                      }`}
                      onClick={() => handleClickReplyButton(ticket.ticketID)}
                      data-tooltip={ticket.ticketReply || ""}
                    >
                      {ticket.ticketReply ? "Replied" : "Reply"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {renderModalContent()}
      </Modal>
    </>
  );
};

export default PZServiceTicket;
